.homepage-card-new {
  margin-top: 4.375rem;
  border-radius: 40px !important;
}
.loginButton{
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000 !important;
  background-color: #f6c82d !important;
  border-color: #f6c82d !important;
}
.container {
  max-width: 1024px !important;
}

.homeBack{
  background: #ffffff url('../assets/images/homePage-background.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 78vh !important;
  max-height: 78vh !important;
}

.card-group{
  position: relative;
  margin-bottom: 100px;
}

.homeBack{
  position: absolute;
    left: 0;
    right: 0;
    top:0;
}


