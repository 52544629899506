/* Header utilities start here */
/* #Navigation
================================================== */

.navigation-wrap {
  position: absolute !important;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
/* .navbar {
  padding-top: 0.25rem;
} */


.navbar-brand {
  padding-top: 0rem;
  width: 275px;
  height: auto;
}
.navbar-brand img {
  height: 100%;
  width: 100%;
  display: block;
  /* filter: brightness(10%); */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #794584 !important;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #794584 !important;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-light .navbar-toggler-icon {
  background-image: none !important;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-bottom: none !important;
}
.nav-item:hover .nav-link {
  color: #fff !important;
}
.nav-item.active .nav-link {
  color: #777 !important;
}
.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}
.navbar-toggler[aria-expanded="true"] .nav-item {
  background-color: rgba(255, 255, 255, 0.6);
}

.nav-item {
  position: relative;
  transition: all 200ms linear;
}

.navbar-light .navbar-toggler {
  background-color: #fff !important;
}

.dropdown-toggle::after {
  display: none;
}

.slider__content span.showMobile {
  text-indent: -24000px;
}

/* Header utilities end here */

/* Footer utilities starts here */
.Footer {
  background: #fff;
  color: #000;
  bottom: 0;
  width: 100%;
 margin-top: 42px;
  z-index: 99;
  position: fixed;
}

.carousel-root{
  margin-bottom: 130px;
}

#footer .container {
  max-width: 1100px !important;
}

#footer .container {
  max-width: 1100px !important;
}

#footer .footer-color {
  background-color: #ececec;
}

.MainFooter {
  padding: 40px 0 20px;
  align-items: flex-start;
}
.MainFooter__info {
  padding-bottom: 20px;
}
.MainFooter__heading {
  font-size: 0.75rem;
  line-height: 6px;
  font-weight: 600;
}
.MainFooter__divider {
  margin-top: 22px;
  margin-bottom: 7px;
}
.Footer a {
  text-decoration: none !important;
  color: #6d7175 !important;
}
.Info__content {
  margin-top: 20px;
}

.MainFooter ul {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.MainFooter ul li {
  font-size: 0.75rem;
  list-style: none;
  margin-right: 40px;
  line-height: 22px;
}

.MainFooter ul li:last-child {
  margin-right: 0;
}

.MainFooter ul.nav2 li {
  margin-right: 26px;
}

.Info__list {
  padding-left: 0;
  margin-bottom: 0;
}
.Info__items {
  font-size: 0.75rem;
  line-height: 22px;
  transition: all 0.3s;
  outline: 0;
  background-color: transparent;
  font-weight: 400;
  font-style: normal;
  line-height: 2.5 !important;
}
.MainFooter__download {
  padding-bottom: 20px;
}
.Download__icons {
  margin-top: 14px;
}
.Download__button {
  padding-left: 0px !important;
}
.MainFooter__poweredBy {
  display: flex;
}
.PoweredBy__info {
  padding-left: 5px;
}
.MainFooter__logo {
  text-align: center;
}
.MainFooter__language {
  font-size: 0.75rem;
  font-weight: 600;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.language__text {
  margin-left: 6px;
}
.SubFooter {
  background-color: #ececec;
  padding: 16px 0;
  font-size: 0.75rem;
  line-height: 0.75rem;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.SubFooter .SubFooter__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SubFooter__branding {
  line-height: 24px;
  color: #6d7175;
}
.SubFooter__connect {
  margin-left: auto;
}
.Connect__icons {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: center;
}
.Connect__icons li {
  display: block;
  width: 25px;
  transition: all 0.3s;
  border-radius: 100%;
  outline: 0;
  background-color: transparent;
  margin-right: 10px;
  text-align: right;
}

/* Footer utilities end here */

.react-multiple-carousel__arrow {
  background: #7e7e7e54 !important ;
  min-height: 3rem !important;
  min-width: 3rem !important;
  border-radius: 50% !important;
}
.react-multiple-carousel__arrow--left::before {
  content: " \003C" !important;
}
.react-multiple-carousel__arrow--right::before {
  content: " \003E" !important;
}
.react-multiple-carousel__arrow::before {
  font-size: 2rem !important;
  font-weight: bold;
}
.sliderHeight {
  /* min-height: 46.1rem !important;
  max-height: 46.1rem !important; */
  height:78vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: top center;
  background-size: cover;
}


.slider__content {
  font-size: 1.125rem;
}

.slider__content span {
  margin-bottom: 15px;
  display: inline-block;
}

.slider__content--action {
  margin-bottom: 20px;
}

.action--slider2 {
  position: relative;
  margin-bottom: 2%;
}

.sliderHeight--slider2 {
  align-items: flex-end;
}

.sliderHeight--slider0 {
  align-items: flex-end;
}

.sliderHeight--slider0 .slider__content{
  color: #794584;
}

.item_container {
  /*  margin-top: 170px; */
  height: auto !important;
  position: relative;
}
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.carousel.carousel-slider .control-arrow {
  width: 3rem !important;
  height: 3rem !important;
  background: #7e7e7e54 !important;
  justify-content: center;
  top: 50% !important;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 50% !important;
  opacity: 1 !important;
}

.carousel .control-prev.control-arrow:before {
  content: " \003c" !important;
  border-right: none !important;
  font-size: 2.5rem !important;
  font-weight: 300;
}

.carousel .control-next.control-arrow:before {
  content: "\003E" !important ;
  border-left: none !important;
  font-size: 2.5rem !important;
  font-weight: 300;
}

.carousel .control-prev.control-arrow {
  left: calc(4% + 1px) !important;
}

.carousel .control-next.control-arrow {
  right: calc(4% + 1px) !important;
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
  display: none;
}

.carousel-status{
  display: none;
}