/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {

  .MainFooter ul{
    margin-bottom: 10px!important;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .homepage-card .card-body{
    padding: 25px!important;
  }
}

/* Tab responsive */
@media (min-width: 768px) and (max-width: 1024px) {
/*   .homepage-card .card-body {
    padding: 20px !important;
    margin-top: 130px !important;
  } */

  .sm-bgPosition {
    background-position: center !important;
  }

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  #footer .row {
    margin-left: 0px;
  }
  .navbar-nav {
    margin-bottom: 30px !important;
  }
  .nav-item {
    background-color: rgba(255, 255, 255, 0.6) !important;
    margin-bottom: 1px;
  }
  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
}

/* Mobile responive */
@media (min-width: 320px) and (max-width: 480px) {
  .react-multiple-carousel__arrow {
    background: #7e7e7e54 !important ;
    min-height: 1.5rem !important;
    min-width: 1.5rem !important;
    border-radius: 50% !important;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 1rem !important;
    font-weight: bold;
  }
  .sm-px-2 {
    padding: 0 2.2rem !important;
  }
  .sm-bgPosition {
    background-position: center !important;
  }
  .homepage-card .card-body {
    padding: 30px !important;
  }
  .header-links {
    margin-right: 26px !important;
  }
  #header .nav-item {
    /* margin-right: 16px !important; */
  }
  .slider__content br {
    display: none !important;
  }
  .slider__content {
    margin-top: 15px;
    width: 320px;
  }
  .nav-item {
    background-color: rgba(255, 255, 255, 0.6) !important;
    margin-bottom: 1px;
  }

  .loginButton {
    position:unset;
    transform: unset;
  }


  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
}

@media (max-device-width:860px){
  .col-sm-12.intro{
    padding-left: 0;
  }

  .item_container{
    max-width: 80%;
    margin: 0 auto;
  }


  .homepage-card .card-body{
    margin-top: 0;
  }

  .Info__items{
    display: flex;
    flex-wrap: wrap;
  }

  .MainFooter__heading .pr{
    margin-right: 0;
  }

  .MainFooter__heading .pl{
    margin-right: 0;
  }

 

  .MainFooter ul{
    margin-bottom: 0!important;
  }

  .MainFooter ul li{
    margin-right: 27px!important;
   
  }

  .MainFooter ul.nav2 li {
    margin-right: 10px!important;
}

.MainFooter ul li:last-child{
  margin-right: 0!important;
}


}


@media (max-device-width: 667px){

  .showMobile{
    text-indent: 0;
  }

  .MainFooter{
  
  }

  .action--slider2 {
    position: relative;
    margin-bottom: 20%!important;
  }

  .item_container h2{
    font-size: 1.4rem;
  }

  .item_container p{
    font-size: 1rem;
  }

  .slider__content span{
    margin-right: 8px;
    display: inline!important;
    margin-bottom: 0;
  }

  .MainFooter__poweredBy{
   justify-content: center;
  }
  
  .MainFooter__heading{
    text-align: center;
  }

  .MainFooter ul.nav2 li{
    text-align: center;
  }

  .MainFooter ul{
    justify-content: center;
  }

  .Download__icons{
    justify-content: center;
  }
  
}